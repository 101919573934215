import Vue from 'vue'
import VueRouter from 'vue-router'
import Dasso from '@/assets/js/Dasso'
Vue.use(VueRouter)

const routes = [
  {
    path: "/addCustomer",
    name: "AddCustomer",
    meta: {
      title: 'Add a customer'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddCustomer.vue")
  },  {
    path: "/customers",
    alias: '/',
    name: "Customers",
    meta: {
      title: 'Customers'
    },
    component: () =>
      import( "../views/GetAllCustomers.vue")
  },
  {
    path: "/customerProfile/" , redirect:'/Customers'
  },
  {
    path: "/customerProfile/:complexId",
    name: "CustomerProfile",
    meta: {
      title: 'Customer Profile'
    },
    component: () =>
      import( "../views/CustomerProfile.vue")
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  next();
})

router.beforeEach((to, from, next) => {
	if(process.env.NODE_ENV !== 'development'){
		Dasso.dassoLogin(to, from, next)
	}else{
		next();
	}
 // Dasso.dassoLogin(to, from, next)
})

export default router
