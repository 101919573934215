<template>
	<div class="text-center mx-auto">
		<v-snackbar
				:value="show"
				:color="color"
				bottom
				:timeout="timeout"				
		>
			<span class="text-h6">{{message}}</span>
		</v-snackbar>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		name: "SnackBar",
		data(){
			return {
				timeout: -1
			}
		},
		computed: {
			...mapState('snackbar', ['show', 'message', 'color'])
		}
	}
</script>

<style scoped>

</style>