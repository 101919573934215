import Vue from "vue";
import Vuex from "vuex";
import * as snackbar from '@/store/modules/snackbar';
import * as validateRule from '@/store/modules/ValidateRules';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version: "v1.3",
    nav: [{
      link: "/addCustomer",
      text: "Add a customer",
      icon: "mdi-account-plus",
    }, {
      link: "/customers",
      text: "Customers",
      icon: "mdi-account-group",
    },
    ],
    msg: '',
    search: '',
    response: '',
    handShow:true,
    drawer: false,
    url: "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net",
    //url:"http://localhost:9090",
    adminComplexId: '',
    allCustomers: [],
    allCustomersWithAnonym: [],
    loyaltyData: {
      panNumber: '',
    },
    loyaltyDataCopy: {
      panNumber: '',
    },
    customer: {
      addresses: [
        {
          address: "",
        }],
    },
    customerCopy: {
      addresses: [
        {
          address: "",
        }],
    },
    addressesCopy: '',
    basketProducts: [{
      basketProducts: [
        {
          basketId: 0,
          id: 0,
          product: {
            category: {
              id: 0,
              image: "",
              name: {},
              parentCategory: 0,
              sorting: 0,
              visible: true
            },
            description: {},
            ean: "",
            id: 0,
            image: "",
            keywords: {},
            marque: "",
            name: {},
            parentSpecificationId: "",
            price: 0
          },
          qty: 0,
          rent: true
        }
      ]
    }
    ]
    ,
  },
  mutations: {
    addComplexIdToNavigate(state, complexId) {
      state.nav[2] = {
        link: "/CustomerProfile/" + complexId,
        text: "Customer profile",
        icon: "mdi-account-edit",
      }
    },
    updateMsg(state, msg) {
      state.msg = msg;
    },
    setBasket(state, basket) {
      state.basketProducts = basket;
    },
    setAdminComplexId(state, val) {
      state.adminComplexId = val
    },
    setLoyaltyData(state, data) {
      state.loyaltyData = data;
    },
    setLoyaltyDataCopy(state, dataCopy) {
      state.loyaltyDataCopy = dataCopy;
    },
    setLoyaltyDataPanNumber(state, panNumber) {
      state.loyaltyData.panNumber = panNumber;
    },
    sethandShow(state,val){
      state.handShow=val
    },

    /*Customer*/
    updateCustomer(state, customer) {
      state.customer = customer;
    },
    updateCustomerCopy(state, customer) {
      state.customerCopy = customer;
    },
    updateCustomerComplexId(state, complexId) {
      state.customer.complexId = complexId
    },
    setAllCustomers(state, all) {
      state.allCustomers = all;
    },
    setAllCustomersWithAnonym(state, all) {
      state.allCustomersWithAnonym = all;
    },
    updateCustomerAddress(state, address) {
      state.customer.addresses = address;
    },
    updateCustomerAddressCopy(state, addressCopy) {
      state.addressesCopy = addressCopy;
    },
  },
  modules: { snackbar, validateRule }
});
