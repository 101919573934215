<template>
  <div class="divClass">
    <v-app-bar
      app
      class="tertiary divClass"
      clipped-left
      flat
      :max-width="drawer ? '256' : ''"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="secondary--text d-flex d-sm-none py-16 px-8"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/">
          <v-img
            src="@/assets/logo-worldline-footer.png"
            contain
            position="left"
          >
          </v-img>
        </router-link>
      </v-toolbar-title>
      <div class="d-none d-sm-flex ml-auto divClass px-3">
        <NavBarTop />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      colored-border
      type="primary"
      v-model="drawer"
      clipped
      app
      disable-resize-watcher
    >
      <NavBarLinks />
      <template v-slot:append>
        <div class="pa-2">
          {{ $store.state.version }}
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import NavBarLinks from "@/components/NavBarLinks";
import NavBarTop from "@/components/NavBarTop";

export default {
  name: "Nav",
  components: {
    NavBarLinks,
    NavBarTop,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>
<style scoped>
/deep/ .v-toolbar__content {
  padding: 0px !important;
}
.divClass{
    height: 100%;
}
</style>
