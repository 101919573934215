var axios = require("axios");
import { getConfig, isNotNull } from "./utils";
const Dasso = {
    dassoLogin(to, from, next) {
        let isLogout = to.query.logout;
        let adminComplexId = to.query.adminComplexId;
        let token = sessionStorage.getItem("Manage_customers_token");
        let needDassoLogin = false;
        let config = getConfig();
        if (isNotNull(adminComplexId)) {
            this.clearDassoStorage();
            let params = new URLSearchParams();
            params.append('adminComplexId', adminComplexId);
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

            axios.post(config.url_ws + "admin/authenticate", params)
                .then(response => {
                    if (response.status === 200) {
                        this.setDassoStorage(response.data);
                        this.toNextRoute(next)
                    } else {
                        alert("Error on get token");
                    }
                })
        } else if (isNotNull(isLogout) && isLogout === "true") {
            let clientId = config.dasso.CLIENT_ID;
            let redirectUri = encodeURI(config.dasso.CLIENT_CALLBACK);
            let dassoLogin = config.dasso.OAUTH2_SERVER + "/authorize.php?client_id=" + clientId + "&response_type=code&redirect_uri=" + redirectUri;
            window.location.replace(dassoLogin);
        } else if (!isNotNull(token)) {
            needDassoLogin = true;
        } else {
            this.toNextRoute(next)
        }

        if (needDassoLogin) {
            localStorage.setItem("toRoute", JSON.stringify(to))
            this.dassoLogout();
        }
    },
    dassoLogout() {
        this.clearDassoStorage();
        let dassoLogout = getConfig().dasso.OAUTH2_SERVER + "/login.php?action=logout&redirect=" + encodeURI(window.location.href.split("?")[0] + "?logout=true");
        window.location.replace(dassoLogout);
    },
    clearDassoStorage() {
        sessionStorage.removeItem("Manage_customers_token");
        sessionStorage.removeItem("Manage_customers_das");
        sessionStorage.removeItem("Manage_customers_name");
        sessionStorage.removeItem("Manage_customers_email");
        //add
        sessionStorage.removeItem("Manage_customers_adminComplexId");
        //add
    },
    setDassoStorage(data) {
        sessionStorage.setItem("Manage_customers_token", data.token);
        sessionStorage.setItem("Manage_customers_das", data.das);
        sessionStorage.setItem("Manage_customers_name", data.name);
        sessionStorage.setItem("Manage_customers_email", data.email);
        sessionStorage.setItem("Manage_customers_isAdmin", data.isAdmin);
        sessionStorage.setItem("Manage_customers_id", data.id);
        sessionStorage.setItem("Manage_customers_adminComplexId",data.adminComplexId);
    },
    toNextRoute(next) {
        let toRoute = JSON.parse(localStorage.getItem("toRoute"));
        localStorage.removeItem("toRoute");
        if (isNotNull(toRoute)) {
            toRoute.replace = true
            next(toRoute)
        } else {
            next();
        }
    }
}

export default Dasso