import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import en from "vuetify/lib/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: "en"
  },
  theme: {
    themes: {
        light:{
        primary: '#1facc9',
        primaryB:'#68b3d5',
        secondary: '#ffffff',
        info: '#343430',
        tertiary:"#343430",
        lightBlue:'#eaf5ff',
        blueWorldline:'#0066a1',
        fourth:"#eaf5ff",
        fifth:"#444d55",
        sixth:"#f2f2f2",
        seventh:"#9a9a98",
        redWorldline:'#f3858e',
        orangeWordline:'#f9a980',
        }
    },
  },
});
