
var axios = require("axios");

var config = new (function () {
	this.url_ws = "https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/";
	this.version = "2.1";
	this.groupComplexId = "3a6ccb3c06a84beaa2dbdf33eb51cd34";
	this.dasso = {
		OAUTH2_SERVER: "https://dasso.kzn.as8677.net",
		CLIENT_CALLBACK: this.url_ws + "admin/token/WESHOP_USERS",
		CLIENT_ID: "0b11518ee992ab6cb22db03112b06149eb3ba6ab"
	}
})();

export function getConfig() {
	return config;
}

export function isNotNull(val) {
	if (val != null && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1) {
		if (!Array.isArray(val)) {
			return true;
		} else if (val.length > 0) {
			return true;
		}
	}
	return false;
}

export function isNull(val) {
	if (val == null || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1) {
		return true;
	} else {
		if (!Array.isArray(val)) {
			return false;
		} else if (val.length === 0) {
			return true;
		}
	}
	return false;
}

export function configAxios() {
	axios.defaults.headers.common['ENV'] = 'WESHOP_USERS';
	axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
	var token = sessionStorage.getItem("Manage_customers_token");
	if (token != null && process.env.NODE_ENV !== 'development') {
		axios.defaults.headers.common['authorization'] = "Bearer " + token;
	}
}

export const REGEX = {
	numberOnly: new RegExp("^[0-9]*$"),
	numberWithDecimal:new RegExp("^[0-9.,]*$"),
	letterOnly: new RegExp("^[a-zA-Zéèçàù ]*$"),
	uppercaseOnly: new RegExp("^[A-Z]*$"),
	lowercaseOnly: new RegExp("^[a-z]*$"),
	letterAndNumber: new RegExp("^[a-zA-Z0-9éèçàù ]*$"),//allow lower, upper and number only.
};
export const checkRegex = function () {//regex first,then any params as you want
	for (var i = 1; i < arguments.length; i++) {
		if (!arguments[0].test(arguments[i]) && arguments[i]!==null && arguments[i]!==undefined && arguments[i]!=="null") {
			return false
		}
	}
	return true;
}
