<template>
    <div class="">
        <div
            v-for="(n,key) in nav" :key="key"
            class="d-flex mx-0 py-0 flex-column linkClick "
            :class="$route.path===n.link?'linkClickb':''"
            >
                <router-link :to="n.link" 
                class="text-decoration-none"
                >
                <div class=" text-left  text-decoration-none d-flex py-5 pl-2 tertiary--text"
                :class="$route.path===n.link?'sixth':''"
                > 
                        <v-icon right :class="$route.path===n.link?'sixth':''" class="primary--text">
                                    {{n.icon}}
                        </v-icon>  
                        <div class="text-decoration-none ml-3 tertiary--text">{{n.text}}</div>
                       
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBarLinks",
    computed:{
        nav(){
            return this.$store.state.nav
        }
    }
}
</script>

<style scoped>
.rowMenu{
    position:relative;
}

.linkClick {
    transition: all .1s;
}
.linkClick:hover{
border-left: #41b4d2 4px solid;
background-color: #ecf0f1;
transition: all .1s;
}
.linkClickb{
border-left:#41b4d2 4px solid;
}

</style>