<template>
    <div class="d-flex linkclass">
        <div
            v-for="(n,key) in nav" :key="key"
            class="d-flex linkClick align-center "
            >
                <router-link :to="n.link" 
                class="text-decoration-none linkclass d-flex  "
                >
                <div class=" text-decoration-none d-flex pl-2 pr-2 secondary--text"
              
                > 
                        <v-icon right  class="primary--text">
                                    {{n.icon}}
                        </v-icon>  
                        <div class="text-decoration-none ml-3 mr-3 secondary--text text-h6 my-auto ">{{n.text}}</div>
                       
                </div>
            </router-link>
        </div>
          <div 
                class="text-decoration-none linkClick d-flex  "
                @click="logOut"
                >
                <div class=" text-decoration-none d-flex pl-2 pr-2 secondary--text"
              
                > 
                        <v-icon right  class="primary--text mx-auto">
                                    mdi-power-standby
                        </v-icon>  
                        
                       
                </div>
            </div>
         <div class="pa-2 secondary--text mx-auto text-center my-auto ">
          {{ $store.state.version }}
        </div>
    </div>
</template>

<script>
import Dasso from '@/assets/js/Dasso' 
export default {
    name: "NavBarTop",
    computed:{
        nav(){
            return this.$store.state.nav
        }
    },
    methods:{
        logOut(){
            Dasso.dassoLogout();
        }
    }
}
</script>

<style scoped>

.linkClick {
    transition: all .1s;
}
.linkClick:hover{
background-color: #4c4e4e;
transition: all .1s;
cursor: pointer;
}
.linkclass{
height: 100%;
}
</style>