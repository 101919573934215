<template>
  <v-app v-if="token">
    <Nav />
    <v-main
      id="main"
      :class="
        $route.path === '/addCustomer' || $route.path === '/customers'
          ? 'secondary'
          : 'sixth'
      "
    >
      <div id="appRoute">
        <transition name="routerTransition" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-main>
    <SnackBar />
  </v-app>
</template>
<script>
import Nav from "@/components/Nav.vue";
import SnackBar from "@/components/SnackBar.vue";
import { configAxios } from "@/assets/js/utils";
export default {
  name: "app",
  data(){
    return{
      token:false,
    }
  },
  components: {
    Nav,
    SnackBar,
  },
  watch: {
    $route: function () {
      this.token = this.isNotNull(sessionStorage.getItem("Manage_customers_token"));
      configAxios();
    },
    token: function(){
      this.$forceUpdate();
    }
  },
};
</script>


<style>
#appRoute,
#main {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  min-height: 100%;
}

#link_div {
  display: flex;
  flex-wrap: wrap;
  z-index: 4;
  background-color: #4d565f;
}

#link {
  position: fixed;
  z-index: 4;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.transi-enter-active {
  transition: all 0.4s ease-in-out;
  animation-name: showDown;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}
.transi-leave-active {
  transition: all 0.4s ease-in-out;
  animation-name: showDown;
  animation-fill-mode: backwards;
  animation-direction: reverse;
  animation-duration: 0.4s;
}
.transi-enter-to {
  transition: all 0.4s ease-in-out;
}
.transi-enter,
.transi-leave-to {
}

@keyframes showDown {
  from {
    left: -400px;
  }
  to {
    left: 0px;
  }
}

#divBackgroundBlack {
  z-index: 3;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: rgba(40, 40, 40, 0.9);
}

.fade-enter-active {
  transition: all 0.4s ease-in-out;
  animation-name: fadeAnim;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}
.fade-leave-active {
  transition: all 0.4s ease-in-out;
  animation-name: fadeAnim;
  animation-fill-mode: backwards;
  animation-direction: reverse;
  animation-duration: 0.4s;
}
.fade-enter-to {
  transition: all 0.4s ease-in-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.routerTransition-enter-active,
.routerTransition-leave-active {
  transition: all 0.2s;
}
.routerTransition-leave-to {
  transform: translateX(-10%);
  opacity: 0;
}
.routerTransition-enter {
  opacity: 0;
  transform: translateX(10%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
</style>
