export const namespaced = true;
import { REGEX, checkRegex } from '@/assets/js/utils'
export const state = {
    letterOnly: (v) => {
        return checkRegex(REGEX.letterOnly, v) || "Only letters are allowed";
    },
    numberOnly: (v) => {
        return checkRegex(REGEX.numberOnly, v) || "Only numbers are allowed";
    },
    letterAndNumber: (v) => {
        return checkRegex(REGEX.letterAndNumber, v) || "Only letters and numbers are allowed";
    },
    numberWithDecimal: (v) => {
        return checkRegex(REGEX.numberWithDecimal, v) || "Only number & decimal number are allowed";
    },
    password: (password, password_b) => {
        if (password === password_b && checkRegex(REGEX.letterAndNumber, password)) {
            return true;
        }
        else if (!checkRegex(REGEX.letterAndNumber, password)) {
            return "Only letters and numbers are allowed";
        }
        else {
            return "passwords should be identicals";
        }
    },
}